import { HTMLSelect, HTMLSelectProps } from '@blueprintjs/core';
import { useField } from 'formik';
import { FC } from 'react';

import { FormGroup } from '../FormGroup';

interface IProps extends HTMLSelectProps {
    name: string;
    className?: string;
    label?: string;
    required?: boolean;
    isContinueBtnClicked?: boolean;
    withEmptyOption?: boolean;
    helperText?: string;
}
interface IFieldProps extends IProps {
    field?: { [key: string]: any };
    meta?: { [key: string]: any };
    isContinueBtnClicked?: boolean;
}

const emptyOption = {
    label: '',
    value: '',
};

export const SelectComponent: FC<IFieldProps> = ({
    className,
    label,
    required,
    field = {},
    meta = {},
    isContinueBtnClicked,
    withEmptyOption = false,
    helperText,
    ...props
}) => {
    if (withEmptyOption) {
        props.options = [emptyOption, ...(props.options || [])];
    }

    const touched = meta?.touched || isContinueBtnClicked;

    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props?.name}
            required={required}
            touched={touched}
            helperText={helperText}
            error={meta?.error}
        >
            <HTMLSelect {...field} {...props} id={props?.name} className="v2__form-select-input" />

            {/* 
                There appears to be a bug in Blueprintjs/core whereby the helpertext is not rendered for HTMLSelect components.
                Without this code, which exists in a similar fashion for Vax in class SelectInput, there are issues rendering 
                error messages, e.g 
                 * gender, pathway, service on Add Visit 
                 * gender  on Add user
                Even an update to the latest blueprintjs/core or blueprintjs/select versions up to 5.16.2 / 5.3.7 does not address the issue
             */}
            {touched && meta?.error ? (
                <div className="bp5-form-helper-text">{meta.error}</div>
            ) : null}
        </FormGroup>
    );
};

const SelectFormComponent: FC<IProps> = ({
    className,
    label,
    required,
    isContinueBtnClicked,
    withEmptyOption = false,
    ...props
}) => {
    const [field, meta] = useField(props);

    return (
        <SelectComponent
            className={className}
            label={label}
            required={required}
            field={field}
            meta={meta}
            isContinueBtnClicked={isContinueBtnClicked}
            withEmptyOption={withEmptyOption}
            {...props}
        />
    );
};

export default SelectFormComponent;
