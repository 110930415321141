import { useMutation, useQuery } from '@apollo/client';
import { JobStatus } from '@doc-abode/data-models';
import { Formik, FormikValues } from 'formik';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { FunctionComponent, useContext, useEffect, useState } from 'react';

import { CREATE_JOB, GET_JOB_BY_ID, UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import {
    getStartDateForDefaultVisitAndAdmin,
    getStartDateForFollowUp,
} from '../../../../../helpers/getStartDateForFollowUp';
import { jobCreator } from '../../../../../helpers/jobCreator/jobCreator';
import useStores from '../../../../../hook/useStores';
import { JobsContext } from '../../../../../providers';
import RootStore from '../../../../../stores/RootStore';
import { DialogAlerts, Dialogs } from '../../../../../stores/UCRStore';
import AppToaster from '../../../../modules/helpers/Toaster';
import { useView } from '../../views/useView';
import { FormMode, FormSteps } from '../common';
import AdminTimeForm from './AdminTimeForm';
import { TAdminTimeData } from './AdminTimeTypes';
import ReviewForm from './ReviewForm';
import { getValidationSchema } from './validation';

type AdminTimeType = {
    isEdit: boolean;
};

const AdminTime: FunctionComponent<AdminTimeType> = ({ isEdit = false }) => {
    const {
        RootStore: {
            configStore: { org },
            ucrStore: {
                setOpenedDialog,
                setOpenedDialogAlert,
                followUpAdminTimeData,
                setFollowUpAdminTimeData,
                focusedJobId,
                selectedDate,
            },
            userStore: {
                user: { username },
            },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    const jobsContext = useContext(JobsContext);

    const { currentViewState } = useView();

    const [createJob, { loading }] = useMutation(CREATE_JOB, {
        onCompleted: () => {
            if (!currentViewState.patientList) {
                if (moment(formData.visitDate).isSame(moment(selectedDate), 'day')) {
                    jobsContext.setRefreshAssignedJobs(true);
                }
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });
    const [updateJob, updateJobData] = useMutation(UPDATE_JOB, {
        onCompleted: () => {
            if (!currentViewState.patientList) {
                if (moment(formData.visitDate).isSame(moment(selectedDate), 'day')) {
                    jobsContext.setRefreshAssignedJobs(true);
                }
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });
    const [visitData, setVisitData] = useState<FormikValues | null>(null);
    const [params, setParams] = useState<{
        step: FormSteps;
        formMode: FormMode;
    }>({
        step: isEdit ? FormSteps.REVIEW : FormSteps.PATIENT,
        formMode: isEdit ? FormMode.EDIT_VISIT : FormMode.DEFAULT,
    });

    const [formData, setFormData] = useState<FormikValues>({
        gender: '',
        hcpId: '',
        activityType: '',
        visitDate: getStartDateForDefaultVisitAndAdmin({
            systemTime: selectedDate,
            fromPatientListView: currentViewState.patientList,
        }),
        startTime: new Date(),
        duration: '01:00',
        createdBy: '',
        lastUpdatedBy: '',
        lastUpdatedDateTime: '',
        createDateTime: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        postCode: '',
        languagesSpoken: [],
        jobStatus: JobStatus.ACCEPTED,
    });

    const [initialised, setInitialised] = useState(false);

    const [patientFieldsRequired, setPatientFieldsRequired] = useState(false);
    const [validationSchema, setValidationSchema] = useState(getValidationSchema({}));

    let initialData = formData;

    /** Get default fields for follow up, some reason duration + startTime do not exist on followUpAdminTimeData */
    if (followUpAdminTimeData) {
        const followUpDataFields = jobCreator({
            values: {
                startTime: new Date(),
                duration: '01:00',
                ...(followUpAdminTimeData as unknown as any),
                ...{
                    _additionalValues: {
                        org,
                        username,
                        id: undefined,
                        users,
                    },
                },
            },
            followUp: true,
            onCreate: true,
            type: 'ADMIN',
        });

        initialData = { ...formData, ...followUpDataFields };
    }

    if (followUpAdminTimeData) {
        initialData.visitDate = getStartDateForFollowUp({
            systemTime: selectedDate,
            dateOfVisit: moment(followUpAdminTimeData.dateOfVisit).toDate(),
            fromPatientListView: currentViewState.patientList,
        });
    }

    const getVisit = useQuery(GET_JOB_BY_ID, {
        variables: {
            id: focusedJobId,
        },
    });

    useEffect(() => {
        const data = getVisit.data?.getJob;

        if (!data || !isEdit) return;
        const adminTypeFields = [
            'nhsNumber',
            'firstName',
            'middleName',
            'lastName',
            'contactNumber',
            'additionalContactNumbers',
            'dateOfBirth',
            'gender',
            'addressLine1',
            'addressLine2',
            'addressLine3',
            'town',
            'postCode',
            'latitude',
            'longitude',
            'systmOneRef',
            'languagesSpoken',
            'staffPreferredGender',
            'activityType',
            'notes',
            'postVisitNotes',
            'id',
        ];

        let filledData = {};
        Object.keys(data).forEach((field: string) => {
            if (adminTypeFields.includes(field) && data[field] !== null) {
                filledData = { ...filledData, [field]: data[field] };
            }
        });

        const formattedVisitData = {
            ...filledData,
            hcpId: data.hcpId,
            visitDate: new Date(data.startDateTime),
            startTime: new Date(data.startDateTime),
            dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth).toDate() : undefined,
            duration: data.duration,
            jobStatus: data.jobStatus,
            ...(data.pds?.versionId && { pds: { versionId: data.pds.versionId } }),
        };

        if (!initialised) {
            setFormData(formattedVisitData as TAdminTimeData);
            setVisitData(formattedVisitData as TAdminTimeData);
            setInitialised(true);
            handleChangePatientRequired(!!(formattedVisitData as TAdminTimeData).nhsNumber);
        }
    }, [initialised, getVisit, isEdit, username]);

    const onSubmit = async (values: FormikValues) => {
        const data = {
            ...cloneDeep(values),
            contactNumber: values.contactNumber ? values.contactNumber : undefined,
            additionalContactNumbers: values.additionalContactNumbers
                ? values.additionalContactNumbers.filter((str: string) => str)
                : undefined,
        };

        setVisitData(data as TAdminTimeData);
        setFormData(values as TAdminTimeData);
    };

    const successSaving = () => {
        AppToaster.show({
            message: `Visit ${isEdit ? 'updated' : 'saved'} successfully!`,
            intent: 'success',
        });

        setFollowUpAdminTimeData(null);
        setOpenedDialog(Dialogs.NONE);
    };

    const saveVisitData = async (visitData: FormikValues) => {
        if (!visitData) return;

        const formattedData = jobCreator({
            values: {
                ...(visitData as unknown as any),
                ...{
                    _additionalValues: {
                        org,
                        username,
                        id: undefined,
                        users,
                    },
                },
            },
            onCreate: true,
            type: 'ADMIN',
        });

        try {
            await createJob({ variables: { input: formattedData } });
        } catch (err) {
            console.error('Error creating visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unabled to create the visit',
                intent: 'danger',
            });
            return;
        }
        successSaving();
    };

    const updateVisitData = async (visitData: FormikValues) => {
        if (!visitData) return;
        const hcpWasChanged = initialData.hcpId !== visitData.hcpId;
        const formattedData = jobCreator({
            values: {
                ...(visitData as unknown as any),
                ...{
                    _additionalValues: {
                        org,
                        username,
                        id: getVisit.data?.getJob.id,
                        users,
                        staffMemberWasChanged: hcpWasChanged,
                    },
                },
            },
            onCreate: false,
            type: 'ADMIN',
        });

        if (formattedData) {
            formattedData.lastUpdatedDateTime = moment().toISOString();
        }

        try {
            await updateJob({ variables: { input: formattedData } });
        } catch (err) {
            console.error('Error updating visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unable to create the visit',
                intent: 'danger',
            });
            return;
        }

        successSaving();
    };

    const onEditReviewForm = (step: FormSteps) => {
        setOpenedDialogAlert(DialogAlerts.SAVE_ADMINISTRATIVE_TIME);
        setParams({
            step,
            formMode: isEdit ? FormMode.EDIT_VISIT : params.formMode,
        });
        setVisitData(null);
    };

    useEffect(() => {
        setOpenedDialogAlert(
            !visitData ? DialogAlerts.SAVE_ADMINISTRATIVE_TIME : DialogAlerts.NONE,
        );
    }, [visitData, setOpenedDialogAlert]);

    useEffect(() => {
        setValidationSchema(getValidationSchema({ patientFieldsRequired }));
    }, [patientFieldsRequired]);

    const handleChangePatientRequired = (isRequired: boolean) =>
        setPatientFieldsRequired(isRequired);

    return (
        <Formik
            initialValues={initialData as TAdminTimeData}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {visitData ? (
                <ReviewForm
                    values={visitData}
                    onSave={isEdit ? updateVisitData : saveVisitData}
                    onEdit={onEditReviewForm}
                    formMode={isEdit ? FormMode.EDIT_VISIT : params.formMode}
                    setVisitData={setVisitData}
                />
            ) : (
                (initialised || !isEdit) && (
                    <AdminTimeForm
                        handleSubmit={isEdit ? updateVisitData : onSubmit}
                        step={params.step}
                        loading={isEdit ? updateJobData.loading : loading}
                        formMode={params.formMode}
                        patientFieldsRequired={patientFieldsRequired}
                        handleChangePatientRequired={handleChangePatientRequired}
                    />
                )
            )}
        </Formik>
    );
};

export default AdminTime;
