import { Patient, PatientAlert } from '@doc-abode/data-models';
import moment from 'moment';
import { FocusEventHandler, useState } from 'react';

import { isAborted, isCompleted } from '../../../../../../helpers/statusCheckHelper';
import filterPatientAlerts from '../../../../../../helpers/ucr/filterPatientAlerts';
import { isAdminJob } from '../../../../../../helpers/ucr/isAdminJob';
import { isMultiAssigneeJob } from '../../../../../../helpers/ucr/isMultiAssigneeJob';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import { Dialogs } from '../../../../../../stores/UCRStore';
import { VisitData } from '../../../forms/AddVisit/AddVisitTypes';
import { usePatientFlagsToChangeStatus } from '../../../hooks/usePatientFlagsToChangeStatus';

interface IProps {
    job: Patient;
    currentVisit?: Patient;
}

// This is used as a symbol between element and focus logic.
// No style should be applied to this long,class name
export const DROPDOWN_ITEM_STYLE = 'drop-down-item-blur-logic-symbol';

export const useInfoPanelActionMenuViewModel = ({ job, currentVisit }: IProps) => {
    const {
        RootStore: {
            ucrStore: {
                setOpenedDialog,
                setAbortVisit,
                setReverseVisit,
                setFollowUpVisitData,
                setFollowUpAdminTimeData,
                setOpenedPatientAlertInfo,
                focusedJobId,
                jobAlerts,
            },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [showDropDown, setShowDropDown] = useState(false);

    const onBlur: FocusEventHandler = ({ relatedTarget }) => {
        // Manual typing because this isn't typed properly upstream
        if ((relatedTarget as HTMLElement | null)?.className?.includes(DROPDOWN_ITEM_STYLE)) {
            // We return here to prevent the buttons being hidden before click event can happen
            return;
        }
        setShowDropDown(false);
    };

    const { jobStatus, buddyJobStatus, startDateTime, dateOfVisit } = job;

    const isAdminTime = isAdminJob(job);
    const { isFirstUser, isDisabled } = usePatientFlagsToChangeStatus(job, true);

    const isHcp1Aborted = isAborted({ jobStatus });
    const isHcp2Aborted = isAborted({ jobStatus: buddyJobStatus });
    const isAnyPartAborted = isHcp1Aborted || isHcp2Aborted;

    const isSelectedJobPartAborted =
        (isFirstUser && isHcp1Aborted) || (!isFirstUser && isHcp2Aborted);

    const isHcp1Completed = isCompleted({ jobStatus });
    const isHcp2Completed = isCompleted({ jobStatus: buddyJobStatus });

    const isSelectedJobPartCompleted =
        (isFirstUser && isHcp1Completed) || (!isFirstUser && isHcp2Completed);

    const isPastTime = moment(startDateTime || dateOfVisit).isBefore(moment(), 'day');

    const { unresolved, resolved, hasUnresolvedAlerts, hasResolvedAlerts } = filterPatientAlerts(
        jobAlerts,
        focusedJobId,
    );

    const patientAlerts = [...resolved, ...unresolved];

    const onOpenJobDetails = () => {
        setOpenedDialog(isAdminTime ? Dialogs.EDIT_ADMINISTRATIVE_TIME : Dialogs.EDIT_VISIT);
        setShowDropDown(false);
    };

    const showCreateFollowUpVisit = job?.jobStatus && !isAdminTime;
    const onCreateFollowUpVisit = () => {
        const visitValues: VisitData = {
            ...currentVisit,
        } as VisitData;
        setFollowUpVisitData(visitValues);
        setOpenedDialog(Dialogs.ADD_NEW_VISIT);
        setShowDropDown(false);
    };

    const showCreateAdmin = job?.jobStatus && !isAdminTime;
    const showCreateFollowUpAdmin = job?.jobStatus && isAdminTime;
    const onCreateFollowUpAdmin = () => {
        const visitValues: VisitData = {
            ...currentVisit,
        } as VisitData;
        setFollowUpAdminTimeData(visitValues);
        setOpenedDialog(Dialogs.ADMINISTRATIVE_TIME);
        setShowDropDown(false);
    };

    const showAbortJob = !isSelectedJobPartAborted && !isSelectedJobPartCompleted;
    const onAbortJob = () => {
        if (job) {
            setAbortVisit(job);
        }
        setShowDropDown(false);
    };

    const showReverseAbortJob = isSelectedJobPartAborted && !isPastTime;
    const onReverseAbortJob = () => {
        if (job) {
            setReverseVisit(job);
        }
        setShowDropDown(false);
    };

    const showChangeToSingleAssignee =
        isMultiAssigneeJob(job) && !isAnyPartAborted && !isSelectedJobPartCompleted;
    const onChangeToSingleAssignee = () => {
        setOpenedDialog(Dialogs.CHANGE_DOUBLE_UP_TO_SINGLE);
        setShowDropDown(false);
    };

    const showChangeStatus = isDisabled;
    const onChangeStatus = () => {
        setOpenedDialog(Dialogs.CHANGE_VISIT_STATUS);
        setShowDropDown(false);
    };

    const showPatientAlert = !isAdminTime && (hasUnresolvedAlerts || hasResolvedAlerts);
    const onOpenPatientAlert = (alert: PatientAlert) => {
        setOpenedPatientAlertInfo(alert);
        setShowDropDown(false);
    };

    return {
        showDropDown,
        setShowDropDown,
        onBlur,
        onOpenJobDetails,
        showPatientAlert,
        patientAlerts,
        onOpenPatientAlert,
        showCreateFollowUpVisit,
        onCreateFollowUpVisit,
        showCreateAdmin,
        showCreateFollowUpAdmin,
        onCreateFollowUpAdmin,
        showAbortJob,
        onAbortJob,
        showReverseAbortJob,
        onReverseAbortJob,
        showChangeToSingleAssignee,
        onChangeToSingleAssignee,
        showChangeStatus,
        onChangeStatus,
    };
};
