import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import _ from 'lodash';
import * as Yup from 'yup';

import useStores from '../../../../hook/useStores';
import { IFilterOption } from '../../../../interfaces';
import RootStore from '../../../../stores/RootStore';
import { genderOptions } from '../../../pages/ucr/components/filters/UcrFilterOptions';
import { splitPracticeDetails } from '../../helpers/formatDataTyped';

const Validation = () => {
    const {
        RootStore: {
            usersStore: { users },
            configStore: { isFeatureEnabled },
            lovsStore: { gender },
        },
    } = useStores<{ RootStore: RootStore }>();

    const validateSelectedGender = (selectedGender?: string) => {
        let genderFound = false;

        genderOptions(gender)?.forEach((gender) => {
            if (gender.value === selectedGender) genderFound = true;
        });

        return genderFound;
    };

    const baseSchema = Yup.object({
        firstName: Yup.string().trim().required('First name is required.'),
        lastName: Yup.string().trim().required('Last name is required.'),
        email: Yup.string()
            .email('Invalid email address provided.')
            .notOneOf(
                users.map((user) => user.email),
                'This email address is already associated with a Doc Abode account. If you need to modify the details of an existing user, please contact Doc Abode customer support.',
            )
            .required('Email address is required.'),
        phoneNumber: Yup.string()
            .required('Mobile phone number is required.')
            .test(
                'phone-number-format',
                'Invalid phone number, please provide a valid mobile number including country code',
                (value) => {
                    if (!value) return false;

                    return isValidPhoneNumber(value, 'GB');
                },
            )
            .test(
                'phone-number-not-exist',
                'This phone number is already associated with a Doc Abode account. If you need to modify the details of an existing user, please contact Doc Abode customer support.',
                (value) => {
                    if (!value) return false;

                    const existingNumber = users.find(
                        (u) =>
                            u.phoneNumber === value ||
                            u.phoneNumber === `0${value.substring(2)}` ||
                            u.phoneNumber === `+${value}`,
                    );

                    return !existingNumber;
                },
            ),
        gender: Yup.string()
            .required('Gender is required.')
            .test('valid-gender', 'Please select a valid gender', (value) => {
                return validateSelectedGender(value);
            }),
        band: Yup.string(),
        roles: Yup.array().of(Yup.string()),
        hcpTypes: Yup.array()
            .of(Yup.string())
            .min(1, 'Please select at least one role.')
            .required('This field is required.'),
        languages: Yup.array().of(Yup.string()),
        specialities: Yup.array().of(Yup.string()),
        practices: Yup.array().of(
            Yup.object().shape({
                label: Yup.string().required(),
                value: Yup.string().required(),
            }),
        ),
        s1Username: Yup.string().trim(),
    });
    let validationSchema = baseSchema;

    if (isFeatureEnabled('s1Enabled')) {
        const s1Schema = Yup.object({ s1Username: Yup.string() });
        validationSchema = baseSchema.concat(s1Schema);
    }

    const initialValues: {
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        gender: string;
        band: string;
        roles: string[];
        hcpTypes: string[];
        languages: string[];
        specialities: string[];
        practices: IFilterOption[];
        s1Username: string;
    } = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        gender: '',
        band: '',
        roles: [],
        hcpTypes: [],
        languages: [],
        specialities: [],
        practices: [],
        s1Username: '',
    };

    const getAPIReadyUserData = (values: typeof initialValues, schema: typeof validationSchema) => {
        const normalizedData: {
            [key: string]: string | string[] | null | Array<{ name: string; odsCode: string }>;
        } = {
            name: values.firstName.trim() + ' ' + values.lastName.trim(),
            email: values.email !== '' ? values.email.toLowerCase().trim() : null,
            phoneNumber: values.phoneNumber !== '' ? values.phoneNumber.trim() : null,
            hcpTypes: values.hcpTypes.length ? values.hcpTypes : [],
            currentPractices: values.practices.length
                ? values.practices.map((practice) => splitPracticeDetails(practice.value))
                : [],
            languages: values.languages.length ? values.languages : [],
            specialities: values.specialities.length ? values.specialities : [],
            gender: values.gender ? values.gender : null,
            band: values.band || null,
            roles: values.roles || [],
            s1Username: values.s1Username ? values.s1Username.trim() : null,
        };

        // Remove empty optional fields which are not arrays
        _.forIn(schema.fields, (fieldValidation, field) => {
            const required = fieldValidation.tests.find((test) => test.OPTIONS.name === 'required');
            if (normalizedData[field] === null && !required) {
                delete normalizedData[field];
            }
        });

        return normalizedData;
    };

    return { validationSchema, initialValues, getAPIReadyUserData };
};

export default Validation;
