import { AbortedDetails } from '@doc-abode/helpers';
import { FC } from 'react';

import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { formatDisplayDateTime } from '../../../../modules/helpers/formatData';

interface IReverseJobHcpAbortDetailsSegment {
    userDisplayLabel?: string;
    abortedDetails?: AbortedDetails;
}

export const ReverseJobHcpAbortDetailsSegment: FC<IReverseJobHcpAbortDetailsSegment> = ({
    userDisplayLabel,
    abortedDetails,
}) => {
    if (!abortedDetails) {
        return <></>;
    }

    return (
        <>
            <ConditionalDisplay show={!!userDisplayLabel}>
                <p data-testid="userDisplay">{userDisplayLabel}</p>
            </ConditionalDisplay>
            <ul className="v2__dialog-block-list">
                <li className="v2__dialog-block-list-item">
                    <span>Aborted on</span>
                    <span data-testid="abortedDateTime">
                        {abortedDetails?.abortedDatetime
                            ? formatDisplayDateTime(abortedDetails?.abortedDatetime)
                            : 'Not set'}
                    </span>
                </li>
                <li className="v2__dialog-block-list-item">
                    <span>Reason</span>
                    <span data-testid="abortedReason">
                        {abortedDetails?.abortedReason || 'Not set'}
                    </span>
                </li>
                <li className="v2__dialog-block-list-item">
                    <span>Notes</span>
                    <span data-testid="abortedNotes">
                        {abortedDetails?.abortedNotes || 'Not set'}
                    </span>
                </li>
            </ul>
        </>
    );
};
