import { Button, Callout, Dialog } from '@blueprintjs/core';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import Select from 'react-select';

import { DIALOGS, useFooterViewModel } from './useFooterViewModel';

const Footer: FC = observer(() => {
    const {
        orgName,
        orgChooserOptions,
        showBeamerWidget,
        formattedAppVersionString,
        openDialog,
        handleOpenDialog,
        handleCloseDialog,
        handleSwitchOrganisation,
    } = useFooterViewModel();

    return (
        <div className="info-bar info-bar--bottom">
            <Callout
                className={classNames('footer', {
                    'with-beamer': showBeamerWidget,
                })}
            >
                <span className="footer__info">
                    {orgChooserOptions.options.length > 1 ? (
                        <Button
                            onClick={() => handleOpenDialog(DIALOGS.SWITCH_ORGANISATION)}
                            text={orgName}
                            outlined
                            icon="office"
                            rightIcon="expand-all"
                            data-testId="OrgChooserMenuButton"
                        />
                    ) : (
                        orgName
                    )}
                    {formattedAppVersionString}
                    {showBeamerWidget && (
                        <Helmet data-testId="BeamerWidget">
                            {/* see public.html for the beamer_config*/}
                            <script src="https://app.getbeamer.com/js/beamer-embed.js"></script>
                        </Helmet>
                    )}
                </span>
            </Callout>
            <Dialog
                isOpen={openDialog === DIALOGS.SWITCH_ORGANISATION}
                onClose={handleCloseDialog}
                title="Organisation"
                icon="office"
            >
                <div className="footer__dialog">
                    <Select
                        options={orgChooserOptions.options}
                        defaultValue={orgChooserOptions.defaultOption}
                        onChange={(event) => handleSwitchOrganisation(event?.value)}
                        placeholder="Select..."
                    />
                </div>
            </Dialog>
        </div>
    );
});

export default Footer;
