import { useQuery } from '@apollo/client';
import { Radio, RadioGroup } from '@blueprintjs/core';
import { JobStatus, Patient } from '@doc-abode/data-models';
import { formatNameLastMiddleFirst } from '@doc-abode/helpers';
import { FC, FormEvent } from 'react';

import { ViewToShow } from '../../../../../../constants/mainConst';
import { GET_JOB_BY_ID } from '../../../../../../graphql/queries/jobs';
import { getHcp } from '../../../../../../helpers/ucr/getHcp';
import { IconClose, IconEdit, IconRefresh } from '../../../../../../helpers/ucr/icons';
import useStores from '../../../../../../hook/useStores';
import RootStore from '../../../../../../stores/RootStore';
import { Dialogs } from '../../../../../../stores/UCRStore';
import { Button, ButtonColors, ButtonElems } from '../../../../../v2/components';
import { CreatedByUpdatedByAudit } from '../../../../../v2/overlays/CreatedByUpdatedByAudit';
import { useJobS1SyncStatusViewModel } from '../../../components/useJobS1SyncStatusViewModel';
import { usePatientFlagsToChangeStatus } from '../../../hooks/usePatientFlagsToChangeStatus';
import { useView } from '../../../views/useView';
import { InfoPanelActionMenu } from './InfoPanelActionMenu';
import S1SyncStatusLabel from './S1SyncStatusLabel';

interface IProps {
    patient: Patient;
    refetch: () => void;
    isAdminTime: boolean;
    isLoading: boolean;
}

const InfoPanelHeader: FC<IProps> = ({ patient, refetch, isAdminTime, isLoading }) => {
    const {
        RootStore: {
            ucrStore: { focusedUser, setOpenedDialog, focusedJobId, setBottomPanelOpen },
            usersStore: { users },
        },
    } = useStores<{ RootStore: RootStore }>();

    const { clearDeepLink, openDeepLink } = useView();

    const {
        firstName,
        lastName,
        middleName,
        jobStatus,
        buddyJobStatus,
        activityType,
        lastUpdatedBy,
        lastUpdatedDateTime,
        createdBy,
        createDateTime,
    } = patient;

    const { isFirstUser, isDoubleUp } = usePatientFlagsToChangeStatus(patient, true);

    const name = `${isAdminTime ? activityType : ''} ${formatNameLastMiddleFirst({
        firstName,
        middleName,
        lastName,
    })}`;

    let visitStatus = isFirstUser
        ? Patient.getFriendlyVisitStatus(jobStatus as JobStatus)
        : Patient.getFriendlyVisitStatus(buddyJobStatus as JobStatus);

    if (!visitStatus) {
        visitStatus = Patient.getFriendlyVisitStatus(JobStatus.PENDING);
    }

    const onRefetch = () => refetch();

    const onChangeFocusedUser = (e: FormEvent<HTMLInputElement>) => {
        openDeepLink(patient.id, e.currentTarget.value);
    };

    const openDialog = () => {
        setOpenedDialog(isAdminTime ? Dialogs.EDIT_ADMINISTRATIVE_TIME : Dialogs.EDIT_VISIT);
    };

    const onClose = () => {
        clearDeepLink();
        setBottomPanelOpen(false);
    };

    const createdByHcp = getHcp(users, createdBy);
    const updatedByHcp = getHcp(users, lastUpdatedBy);

    const currentVisit = useQuery(GET_JOB_BY_ID, {
        variables: {
            id: focusedJobId,
        },
        pollInterval: 60000,
    }).data?.getJob;

    const { getS1SyncStatusDisplayLabel } = useJobS1SyncStatusViewModel(patient);

    return (
        <header className="visit-details__header">
            <div className="visit-details__header-main-info">
                {isLoading ? (
                    <span className="visit-details__header-text">Loading visit details...</span>
                ) : (
                    <div className="visit-details__header-left-side">
                        <span className="visit-details__header-text">{name}</span>
                        <span
                            className={'visit-details__header-tag drop-down-item-blur-logic-symbol'}
                        >
                            {visitStatus}
                        </span>
                        <S1SyncStatusLabel
                            patient={patient}
                            isBuddy={!isFirstUser}
                            label={`S1 ${getS1SyncStatusDisplayLabel(!isFirstUser).toUpperCase()}`}
                            view={ViewToShow.VISIT_DETAILS}
                        />
                        <Button
                            color={ButtonColors.PINK}
                            Icon={IconEdit}
                            clickEvent={openDialog}
                            elem={ButtonElems.BUTTON}
                        />
                        <Button
                            color={ButtonColors.PINK}
                            Icon={IconRefresh}
                            clickEvent={onRefetch}
                            elem={ButtonElems.BUTTON}
                        />
                        {isDoubleUp && focusedUser && (
                            <RadioGroup
                                className="visit-details__header-user-group"
                                label="Show details for staff member"
                                selectedValue={focusedUser}
                                onChange={onChangeFocusedUser}
                            >
                                <Radio
                                    className="visit-details__header-user-radio"
                                    label="#1"
                                    value="user1"
                                />
                                <Radio
                                    className="visit-details__header-user-radio"
                                    label="#2"
                                    value="user2"
                                />
                            </RadioGroup>
                        )}
                    </div>
                )}
                <div className="visit-details__header-right-side">
                    <InfoPanelActionMenu job={patient} currentVisit={currentVisit} />

                    <Button
                        className="visit-details__close-btn"
                        color={ButtonColors.PINK}
                        Icon={IconClose}
                        clickEvent={onClose}
                        elem={ButtonElems.BUTTON}
                    />
                </div>
            </div>
            <div className="v2__dialog-header-update-by">
                <CreatedByUpdatedByAudit
                    createdBy={createdByHcp?.userName || createdBy}
                    createdAt={createDateTime}
                    updatedBy={updatedByHcp?.userName || lastUpdatedBy}
                    updatedAt={lastUpdatedDateTime}
                />
            </div>
        </header>
    );
};

export default InfoPanelHeader;
