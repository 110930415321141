import { IHcp } from '../../interfaces/ucr';
import { getHcp } from './getHcp';

export function isUserSick(userId?: string | null, users?: IHcp[]) {
    if (!userId) {
        return false;
    }

    if (!users) {
        return false;
    }

    const user = getHcp(users, userId);
    return Boolean(user?.absent);
}
