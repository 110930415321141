import { useState } from 'react';

import packageInfo from '../../../package.json';
import { getFilteredOrganisations } from '../../helpers/getFilteredOrganisations';
import useStores from '../../hook/useStores';
import RootStore from '../../stores/RootStore';

export const DIALOGS = {
    SWITCH_ORGANISATION: 'SWITCH_ORGANISATION',
};

export const useFooterViewModel = () => {
    const {
        RootStore: {
            configStore: {
                org,
                switchOrganisation,
                name: orgName,
                features,
                loaded: hasConfigStoreBeenLoaded,
            },
            userStore: { organisationsInfo, user },
        },
    } = useStores<{ RootStore: RootStore }>();

    const [openDialog, setOpenDialog] = useState<string | null>(null);

    // The space is intentional to offset the version from the org name or org chooser
    const formattedAppVersionString = ` v${packageInfo.version}`;

    const handleOpenDialog = (dialog: string) => {
        setOpenDialog(dialog);
    };

    const handleCloseDialog = () => {
        setOpenDialog(null);
    };

    const handleSwitchOrganisation = (value?: string) => {
        handleCloseDialog();
        if (value && value !== org) {
            switchOrganisation(value);
        }
    };

    const allOrganisations = [...organisationsInfo]
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));
    const defaultOrgName = allOrganisations.find(
        (organisation) => organisation.value === org,
    )?.name;

    const filteredOrganisations = getFilteredOrganisations(allOrganisations, user);

    // Beamer should be shown by default - whether enabled or never defined
    // Only hide Beamer if the feature is set to false, e.g. Beamer causes issues
    const isBeamerEnabled = features['beamer'] !== false;

    return {
        orgName,
        orgChooserOptions: {
            options: filteredOrganisations.map(({ value, name }) => ({
                label: name,
                value,
            })),
            defaultOption: {
                label: defaultOrgName,
                value: org,
            },
        },
        showBeamerWidget: Boolean(isBeamerEnabled && hasConfigStoreBeenLoaded && user.username),
        formattedAppVersionString,
        openDialog,
        handleOpenDialog,
        handleCloseDialog,
        handleSwitchOrganisation,
    };
};
