import { observer } from 'mobx-react';
import { FC } from 'react';

import { ConditionalDisplay } from '../../CondtionalDisplay';
import { formatDisplayDate, formatDisplayTime } from '../../modules/helpers/formatData';

interface IProps {
    createdBy?: string;
    createdAt?: string;
    updatedBy?: string;
    updatedAt?: string;
}

export const CreatedByUpdatedByAudit: FC<IProps> = observer(
    ({ createdBy, createdAt, updatedBy, updatedAt }) => {
        return (
            <>
                <span className="v2__dialog-header-update-by">
                    created by {createdBy} on&nbsp;
                    {formatDisplayDate(createdAt)}&nbsp;
                    {formatDisplayTime(createdAt)}
                </span>
                <ConditionalDisplay show={!!updatedAt}>
                    <span className="v2__dialog-header-update-by">
                        last updated by {updatedBy} on&nbsp;
                        {formatDisplayDate(updatedAt)}&nbsp;
                        {formatDisplayTime(updatedAt)}
                    </span>
                </ConditionalDisplay>
            </>
        );
    },
);
